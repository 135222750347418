export const environment = {
  production: true,
/* apiURL : 'http://localhost:8000/api',
   imgURL : 'http://localhost:8000/immagini',
   vulnerabilitaImgURL : 'http://localhost:8000/vulnerabilita',
   pdfURL : 'http://localhost:8000/mappe',
   schedeURL : 'http://localhost:8000/schede'*/
  apiURL : 'https://dante.irpi.cnr.it/api',
  imgURL : 'https://dante.irpi.cnr.it/immagini',
  vulnerabilitaImgURL : 'https://dante.irpi.cnr.it/vulnerabilita',
  pdfURL : 'https://dante.irpi.cnr.it/mappe',
  schedeURL : 'https://dante.irpi.cnr.it/schede'
};
