import {Component, OnInit, ViewChild} from '@angular/core';
import {User} from '../_models/user.model';
import {ApiService} from '../api.service';
import {ActivatedRoute, Router} from '@angular/router';
import { MessageService} from 'primeng/api';
import {NgForm} from '@angular/forms';
import {AuthService} from '../auth.service';

@Component({
  selector: 'app-utenti-password',
  templateUrl: './utenti-password.component.html',
  styleUrls: ['./utenti-password.component.css']
})
export class UtentiPasswordComponent implements OnInit {

  public utente: User;
  public password: string
  public password2: string;

  @ViewChild('f') formValues;

  constructor(public api: ApiService, public route: ActivatedRoute, public message: MessageService,
              public auth: AuthService, public router: Router) {

    if (!this.auth.userIsAdmin()) {
       this.router.navigate(['/login']);
    }

    this.utente = new User();
    const id = this.route.snapshot.paramMap.get("id");

    this.api.getUtente(id).subscribe(
        data => {
            this.utente = data['data'];
        }
    );

  }

  ngOnInit() {
  }

  onSubmit(form: NgForm) {

    if (this.password.length < 8) {
        this.message.add(
            {
                severity: 'error',
                summary: 'Errore',
                detail: 'la password deve essere di almeno 8 caratteri'
            }
        );
        return false;
    }

    if (this.password !== this.password2) {
          this.message.add(
              {
                  severity: 'error',
                  summary: 'Errore',
                  detail: 'le password non coincidono'
              }
          );
          return false;
      }

    this.api.changePasswordUtente(this.utente.id, this.password).subscribe(
          data => {
              if (data['status'] === 'ok') {
                  this.message.add(
                      {
                          severity: 'success',
                          summary: 'Conferma',
                          detail: 'La password è stata modificata con successo!'
                      }
                  );
                  this.formValues.resetForm();
              } else {
                  this.message.add(
                      {
                          severity: 'error',
                          summary: 'Errore',
                          detail: data['reason']
                      }
                  );
              }
          }
      );
  }

}
