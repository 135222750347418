import {Component, OnInit, ViewChild} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from '../api.service';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  public email: string;

  constructor(public api: ApiService, public auth: AuthService, public router: Router, public message: MessageService) {
    if (auth.isLoggedIn()) {
      this.router.navigate(['/new-password']);
    }
  }

  ngOnInit() {
  }

  onSubmit(form: NgForm) {

    this.api.resetPassword(this.email).subscribe(
        data => {
          if (data['status'] === 'ko') {
              this.email = '';
              this.message.add(
                  {
                      severity: 'error',
                      summary: 'Errore',
                      detail: data['reason']
                  }
              );
          }

          if (data['status'] === 'ok') {
              this.email = '';
              this.message.add(
                  {
                      severity: 'success',
                      summary: 'Messaggio inviato',
                      detail: 'Ti è stata inviata una email con un link per resettare la password'
                  }
              );
          }

        }
    );

  }

}
