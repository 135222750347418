import {Component, OnInit, ViewChild} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from '../api.service';
import { AuthService } from '../auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public mex = '';

  @ViewChild('f') formValues;

  constructor(public api: ApiService, public auth: AuthService, public router: Router,
              public message: MessageService, public route: ActivatedRoute) {

      if (this.route.snapshot.paramMap.get('param') === 'resetok') {
          this.mex = 'La nuova password è stata impostata';
      }

      if (this.route.snapshot.paramMap.get('param') === 'resetko') {
          this.mex = 'Il link per il recupero password non è più valido, richiedere un nuovo recupero';
      }

      if (auth.isLoggedIn()) {
         this.router.navigate(['/arginature']);
      }

  }

  ngOnInit() {
  }

  onSubmit(form: NgForm) {

    const username = form.value.username;
    const password = form.value.password;

    this.api.login(username, password).subscribe(

        result => {

            if ('access_token' in result) {
                localStorage.setItem('jwt_access_token', result['access_token']);
            }

            if ('expires_in' in result) {
                localStorage.setItem('jwt_expires_in', result['expires_in']);
            }

            if ('token_type' in result) {
                localStorage.setItem('jwt_token_type', result['token_type']);
            }

            if (this.auth.isLoggedIn()) {
                this.router.navigate(['/home']);
            }


        },

        error => {
          if (error === 'Unauthorized') {
              this.formValues.resetForm();
              this.message.add(
                  {
                      severity: 'error',
                      summary: 'Errore',
                      detail: 'Login errato'
                  }
              );
          } else {
            alert('Si è verificato un errore inatteso, provare a ricaricare la pagina');
          }

        }
    );

  }
}
