import {Component, OnInit, ViewChild} from '@angular/core';
import { User } from '../_models/user.model';
import {ApiService} from '../api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {NgForm} from '@angular/forms';
import {AuthService} from '../auth.service';

@Component({
  selector: 'app-utenti-edit',
  templateUrl: './utenti-edit.component.html',
  styleUrls: ['./utenti-edit.component.css']
})
export class UtentiEditComponent implements OnInit {

 public utente: User;
 public isNew = true;

 public ruolo = [
      { label: 'Admin', value: 'admin'},
      { label: 'Lettura e scrittura', value: 'rw'},
      { label: 'Sola lettura', value: 'ro'}
 ];

 @ViewChild('f') formValues;

 constructor(public api: ApiService, public route: ActivatedRoute, public message: MessageService,
             public auth: AuthService, public router: Router) {

     if (!this.auth.userIsAdmin()) {
         this.router.navigate(['/login']);
     }

     this.utente = new User();
     const id = this.route.snapshot.paramMap.get("id");

     if (id) {
         this.isNew = false;
     }

     if (!this.isNew) {

         this.api.getUtente(id).subscribe(
             data => {
                 this.utente = data['data'];
             }
         );
     }
 }

 ngOnInit() {
 }


 onSubmit(form: NgForm) {

     if (this.isNew) {
         this.api.storeUtente(this.utente).subscribe(
             data => {
                 if (data['status'] === 'ok') {

                     this.formValues.resetForm();

                     this.message.add(
                         {
                             severity: 'success',
                             summary: 'Conferma',
                             detail: 'L\'utente è stato aggiunto con successo!'
                         }
                     );

                 } else {

                     this.message.add(
                         {
                             severity: 'error',
                             summary: 'Errore',
                             detail: data['reason']
                         }
                     );

                 }
             }
         );
     } else {

         this.api.updateUtente(this.utente).subscribe(
             data => {

                 if (data['status'] === 'ok') {

                     this.message.add(
                         {
                             severity: 'success',
                             summary: 'Conferma',
                             detail: 'L\'utente è stato modificato con successo!'
                         }
                     );

                 } else {

                     this.message.add(
                         {
                             severity: 'error',
                             summary: 'Errore',
                             detail: data['reason']
                         }
                     );

                 }

             }
         );

     }

 }
}
