import {Component, OnInit, Output, ViewChild} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSort, MatTableDataSource, MatPaginator } from '@angular/material';
import { DataSource } from '@angular/cdk/table';
import { DropdownModule } from 'primeng/primeng';

import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ApiService} from '../api.service';
import {User} from '../_models/user.model';
import { AuthService} from '../auth.service';
import {Router} from '@angular/router';


export interface DataElement {
  nome: string;
  cognome: string;
  email: string;
  ruolo: string // tendina
}


@Component({
  selector: 'app-utenti',
  templateUrl: './utenti.component.html',
  styleUrls: ['./utenti.component.css']
})

export class UtentiComponent implements OnInit {

  public users: Array<User>;

  public ruoli = [
      { label: 'Tutti', value: null},
      { label: 'Admin', value: 'admin'},
      { label: 'Lettura e scrittura', value: 'rw'},
      { label: 'Sola lettura', value: 'ro'},
  ];

  public getRuoloLabel(ruolo) {
      if (ruolo === 'admin') {
          return 'Admin';
      } else if (ruolo === 'rw') {
          return 'Lettura e scrittura';
      } else {
          return 'Sola lettura';
      }

  }

  constructor(public auth: AuthService, public http: HttpClient, public confirm: ConfirmationService,
              public api: ApiService, public messageService: MessageService,
              public router: Router) {

     if (!this.auth.userIsAdmin()) {
         this.router.navigate(['/login']);
     }

     this.users = new Array();

     this.api.getUtenti().subscribe(
      data => {
        this.users = data['data'];
      }
    );

  }

  ngOnInit() {
  }

  confirmDelete(element) {
      this.confirm.confirm({
            message: 'Procedere con la cancellazione?',
            header: 'Richiesta conferma',
            acceptLabel: 'sì',
            rejectLabel: 'no',
            accept: () => {
                this.api.deleteUtente(element.id).subscribe(
                    data => {
                        if (data['status'] === 'ok') {
                            const index = this.users.indexOf(element);
                            this.users = this.users.filter((val, i) => i !== index );
                        } else {
                            console.log('ko');
                            this.messageService.add(
                                {severity: 'error',
                                    summary: 'Errore',
                                    detail: data['reason']}
                            );
                        }
                    }
                );
            }
        });
  }

}
