import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../api.service';
import { ActivatedRoute, Router} from '@angular/router';
import { Arginatura } from '../_models/arginatura.model';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NgForm} from '@angular/forms';
import { UploadService } from '../upload.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { Immagine } from '../_models/immagine.model';
import { Mappa } from '../_models/mappa.model';
import {AuthService} from '../auth.service';

export class Provincia {
  value: string;
  regione: string;
}

@Component({
  selector: 'app-arginature-edit',
  templateUrl: './arginature-edit.component.html',
  styleUrls: ['./arginature-edit.component.css']
})
export class ArginatureEditComponent implements OnInit {

  public arginatura: Arginatura;

  public isNew = true;

  @ViewChild('f') formValues;
  @ViewChild('fileUpload50') fileUpload50;
  @ViewChild('fileUpload200') fileUpload200;
  @ViewChild('fileUpload500') fileUpload500;
  @ViewChild('fileUploadPdf') fileUploadPdf;
  @ViewChild('fileUploadScheda') fileUploadScheda;

  public uploading50 = false;
  public uploading200 = false;
  public uploading500 = false;

  public uploadingPdf = false;
  public uploadingScheda = false;

  public regioni = [
    { label: 'Tutte', value: null, province: []},
  ];

  public province = [
  ];

  public corsodacqua = [
  ];

  public sormonto = [
    { label: 'N.D.', value: null},
    { label: 'Nulla', value: 'nulla'},
    { label: 'Bassa', value: 'bassa'},
    { label: 'Media', value: 'media'},
    { label: 'Alta', value: 'alta'}
  ];

  public p_idraulica_options = [
    { label: 'N.D.', value: null},
    { label: 'Integro', value: 'integro'},
    { label: 'Rotta arginale', value: 'rotta arginale'}
  ];

  public sifonamento = [
    { label: 'N.D.', value: null},
    { label: 'Nulla', value: 'nulla'},
    { label: 'Bassa', value: 'bassa'},
    { label: 'Media', value: 'media'},
    { label: 'Alta', value: 'alta'},
    { label: 'Tracimato', value: 'tracimato'}
  ];

  constructor(public api: ApiService, public route: ActivatedRoute,
              public message: MessageService,
              public uploadService: UploadService,
              public confirm: ConfirmationService,
              public auth: AuthService,
              public router: Router) {

      if (!this.auth.userCanWrite()) {
          this.router.navigate(['/login']);
      }

      this.arginatura = new Arginatura();

  }

  ngOnInit() {

    const id = this.route.snapshot.paramMap.get("id");
    if (id) {
        this.isNew = false;
        this.arginatura.id = parseInt(id, 10);
    } else {
        this.api.storeArginatura().subscribe(
          data => {
              if (data['status'] === 'ok') {
                  this.arginatura.id = data['id'];
              } else {
                  //
              }
          }
        );
        // this.arginatura.hash = "tmp-" + Math.round(Math.random() * 1000000 + 1000000);
    }

    this.api.getRegioniProvince().subscribe(
        dataP => {
          this.regioni = dataP['data'];

          this.api.getCorsi().subscribe(
              dataC => {
                  const d = dataC;
                  d['data'].forEach(c => {
                      this.corsodacqua.push({label: c.nome, value: c.id});
                  });

                  if (!this.isNew) {
                     this.api.getArginatura(this.arginatura.id).subscribe(
                         dataA => {
                           this.arginatura = dataA['data'];
                           this.regioni.forEach(r => {
                              if (r.value === this.arginatura.regione_id) {
                                this.province = r.province;
                              }
                           });

                         }
                     );
                  }

              }
          );

        }
    );


  }

  onChangeRegione($event) {

    this.regioni.forEach(r => {
      if (r. value === $event.value) {
        this.province = r.province;
      }
    });
  }


  onSubmit(form: NgForm) {

      if (this.isNew) {

          if (!this.arginatura.id) {
              return;
          }

          this.api.updateArginatura(this.arginatura).subscribe(
              data => {
                  if (data['status'] === 'ok') {

                      this.arginatura.id = null;
                      this.formValues.resetForm();

                      this.message.add(
                          {
                              severity: 'success',
                              summary: 'Conferma',
                              detail: 'Arginatura creata con successo!'
                          }
                      );

                      this.api.storeArginatura().subscribe(
                          data => {
                              if (data['status'] == 'ok') {
                                  this.arginatura.id = data['id'];
                              }
                          }
                      );

                  } else {

                      this.message.add(
                          {
                              severity: 'error',
                              summary: 'Errore',
                              detail: data['reason']
                          }
                      );

                  }
              }
          );
      } else {

          this.api.updateArginatura(this.arginatura).subscribe(
              data => {

                  if (data['status'] === 'ok') {

                      this.message.add(
                          {
                              severity: 'success',
                              summary: 'Conferma',
                              detail: 'L\'arginatura è stata modificata con successo!'
                          }
                      );

                  } else {

                      this.message.add(
                          {
                              severity: 'error',
                              summary: 'Errore',
                              detail: data['reason']
                          }
                      );

                  }

              }
          );

      }

  }

  upload(event, tr) {

      if (tr === 50) {
          this.uploading50 = true;
      } else if (tr === 200) {
          this.uploading200 = true;
      } else if (tr === 500) {
          this.uploading500 = true;
      }

      let id: string;

      if (this.arginatura.id) {
          id = this.arginatura.id.toString();
      } else {
          id = this.arginatura.hash;
      }

      let uploaded = 0;

      for (let i = 0; i < event.files.length; i++) {
          this.uploadService.uploadImg(this.api.getApiUrl() + '/arginature/upload', event.files[i], id + '-' + i, tr).subscribe(
              data => {
                  if ('body' in data) {
                      if ((data['body'].status) === 'ok') {
                          uploaded = uploaded + 1;
                          if (uploaded === event.files.length) {
                              if (tr === 50) {
                                  this.uploading50 = false;
                                  this.fileUpload50.clear();
                                  let img = new Immagine();
                                  img.id = data['body'].id;
                                  img.filename = data['body'].filename;
                                  img.width = data['body'].width;
                                  img.height = data['body'].height;
                                  this.arginatura.immagini.tr50.push(img);
                              } else if (tr === 200) {
                                  this.uploading200 = false;
                                  this.fileUpload200.clear();
                                  let img = new Immagine();
                                  img.id = data['body'].id;
                                  img.filename = data['body'].filename;
                                  img.width = data['body'].width;
                                  img.height = data['body'].height;
                                  this.arginatura.immagini.tr200.push(img);
                              } else if (tr === 500) {
                                  this.uploading500 = false;
                                  this.fileUpload500.clear();
                                  let img = new Immagine();
                                  img.id = data['body'].id;
                                  img.filename = data['body'].filename;
                                  img.width = data['body'].width;
                                  img.height = data['body'].height;
                                  this.arginatura.immagini.tr500.push(img);
                              }
                              this.message.add(
                                  {
                                      severity: 'success',
                                      summary: 'Conferma',
                                      detail: 'Caricamento avvenuto con successo'
                                  }
                              );
                          }
                      }
                  }
              }
          );
      }

  }

  deleteImmagine(event, id) {
     event.preventDefault();

     this.confirm.confirm({
          message: 'Procedere con la cancellazione?',
          header: 'Richiesta conferma',
          acceptLabel: 'sì',
          rejectLabel: 'no',
          accept: () => {
              this.api.deleteImmagine(id).subscribe(
                  data => {
                      if (data['status'] === 'ok') {
                          this.message.add(
                              {
                                  severity: 'success',
                                  summary: 'Conferma',
                                  detail: 'L\'immagine è stata rimossa'
                              }
                          );
                          this.arginatura.immagini.tr50 = this.arginatura.immagini.tr50.filter(
                              el => {
                                  if (el.id !== id) {
                                      return true;
                                  } else {
                                      return false;
                                  }
                              }
                          );
                          this.arginatura.immagini.tr200 = this.arginatura.immagini.tr200.filter(
                              el => {
                                  if (el.id !== id) {
                                      return true;
                                  } else {
                                      return false;
                                  }
                              }
                          );
                          this.arginatura.immagini.tr500 = this.arginatura.immagini.tr500.filter(
                              el => {
                                  if (el.id !== id) {
                                      return true;
                                  } else {
                                      return false;
                                  }
                              }
                          );
                      } else {
                          this.message.add(
                              {
                                  severity: 'error',
                                  summary: 'Errore',
                                  detail: data['reason']
                              }
                          );
                      }
                  }
              );
          }
      });

  }


  uploadPdf(event) {

      this.uploadingPdf = true;

      let id: string;

      if (this.arginatura.id) {
          id = this.arginatura.id.toString();
      }

      let uploaded = 0;

      for (let i = 0; i < event.files.length; i++) {
          this.uploadService.uploadPdf(this.api.getApiUrl() + '/arginature/uploadpdf', event.files[i], id + '-' + i).subscribe(
              data => {
                  if ('body' in data) {
                      if ((data['body'].status) === 'ok') {
                          uploaded = uploaded + 1;
                          if (uploaded === event.files.length) {
                              this.uploadingPdf = false;
                              this.fileUploadPdf.clear();
                              let mappa = new Mappa();
                              mappa.id = data['body'].id;
                              mappa.filename = data['body'].filename;
                              this.arginatura.mappe.push(mappa);
                          }
                          this.message.add(
                              {
                                  severity: 'success',
                                  summary: 'Conferma',
                                  detail: 'Caricamento avvenuto con successo'
                              }
                          );
                      }
                  }
              }
          );
      }
  }



   deleteMappa(event, id) {
        event.preventDefault();

        this.confirm.confirm({
            message: 'Procedere con la cancellazione?',
            header: 'Richiesta conferma',
            acceptLabel: 'sì',
            rejectLabel: 'no',
            accept: () => {
                this.api.deleteMappa(id).subscribe(
                    data => {
                        if (data['status'] === 'ok') {
                            this.message.add(
                                {
                                    severity: 'success',
                                    summary: 'Conferma',
                                    detail: 'Il file è stato rimosso'
                                }
                            );
                            this.arginatura.mappe = this.arginatura.mappe.filter(
                                el => {
                                    if (el.id !== id) {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                }
                            );

                        } else {
                            this.message.add(
                                {
                                    severity: 'error',
                                    summary: 'Errore',
                                    detail: data['reason']
                                }
                            );
                        }
                    }
                );
            }
        });

    }



    uploadScheda(event) {

        this.uploadingScheda = true;

        let id: string;

        if (this.arginatura.id) {
            id = this.arginatura.id.toString();
        }

        for (let i = 0; i < event.files.length; i++) {
            this.uploadService.uploadScheda(this.api.getApiUrl() + '/arginature/uploadscheda', event.files[i], id).subscribe(
                data => {
                    if ('body' in data) {

                        if ((data['body'].status) === 'ok') {

                            this.uploadingScheda = false;
                            this.fileUploadScheda.clear();
                            this.arginatura.scheda = data['body'].filename;

                            this.message.add(
                                {
                                    severity: 'success',
                                    summary: 'Conferma',
                                    detail: 'Caricamento avvenuto con successo'
                                }
                            );
                        }
                    }
                }
            );
        }
    }

    deleteScheda(event) {
        event.preventDefault();

        let id = this.arginatura.id;

        this.confirm.confirm({
            message: 'Procedere con la cancellazione?',
            header: 'Richiesta conferma',
            acceptLabel: 'sì',
            rejectLabel: 'no',
            accept: () => {
                this.api.deleteScheda(id).subscribe(
                    data => {
                        if (data['status'] === 'ok') {
                            this.message.add(
                                {
                                    severity: 'success',
                                    summary: 'Conferma',
                                    detail: 'Il file è stato rimosso'
                                }
                            );
                            this.arginatura.scheda = '';
                        } else {
                            this.message.add(
                                {
                                    severity: 'error',
                                    summary: 'Errore',
                                    detail: data['reason']
                                }
                            );
                        }
                    }
                );
            }
        });

    }

}
