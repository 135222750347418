import {Component, OnInit, Output, ViewChild} from '@angular/core';

import { MatSort, MatTableDataSource, MatPaginator } from '@angular/material';
import { DataSource } from '@angular/cdk/table';
import { DropdownModule } from 'primeng/primeng';

import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import {ApiService} from '../api.service';
import { MessageService } from 'primeng/api';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';



export interface DataElement {
  corso_d_acqua: string;
}


@Component({
  selector: 'app-test',
  templateUrl: './corsidacqua.component.html',
  styleUrls: ['./corsidacqua.component.css']
})

export class CorsidacquaComponent implements OnInit {

  public jsonCorsi;

  constructor(public api: ApiService, public confirm: ConfirmationService,
              public messageService: MessageService,
              public auth: AuthService,
              public router: Router) {

    if (!this.auth.userCanWrite()) {
          this.router.navigate(['/login']);
    }

    this.api.getCorsi().subscribe(
      data => {
        this.jsonCorsi = data['data'];
      }
    );

  }

  ngOnInit() {
  }

  confirmDelete(element) {
        this.confirm.confirm({
            message: 'Procedere con la cancellazione?',
            header: 'Richiesta conferma',
            acceptLabel: 'sì',
            rejectLabel: 'no',
            accept: () => {
                this.api.deleteCorso(element.id).subscribe(
                    data => {
                        if (data['status'] === 'ok') {
                            const index = this.jsonCorsi.indexOf(element);
                            this.jsonCorsi = this.jsonCorsi.filter((val, i) => i !== index );
                        } else {
                            console.log('ko');
                            this.messageService.add(
                                {severity: 'error',
                                    summary: 'Errore',
                                    detail: data['reason']}
                            );
                        }
                    }
                );

            }
        });
  }

}
