import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { ActivatedRoute } from '@angular/router';
import { Arginatura } from '../_models/arginatura.model';
import { MessageService } from 'primeng/api';
import {AuthService} from '../auth.service';

@Component({
  selector: 'app-arginature-view',
  templateUrl: './arginature-view.component.html',
  styleUrls: ['./arginature-view.component.css']
})
export class ArginatureViewComponent implements OnInit {

  public datiSezioneIniziale: Array<any>;
  public datiSezioneFinale: Array<any>;
  public showGraphs = false;

  public chartOptionsIniziale = {
    title: 'Dati sezione iniziale',
    height: '640',
    hAxis: { title: 'metri'},
    vAxis: { title: 'metri'},
    explorer: {
        axis: 'horizontal',
        keepInBounds: false,
        maxZoomIn: 4.0,
    },
    legend: { position: 'none' }
  };

  public chartOptionsFinale = {
        title: 'Dati sezione finale',
        height: '640',
        hAxis: { title: 'metri'},
        vAxis: { title: 'metri'},
        explorer: {
            axis: 'horizontal',
            keepInBounds: false,
            maxZoomIn: 4.0,
        },
        legend: { position: 'none' }
    };

  public argine: Arginatura;
  public imgUrl: string;

  public mappePdf = [];

  constructor(public api: ApiService, public route: ActivatedRoute, public message: MessageService,
              public auth: AuthService) {
      this.argine = new Arginatura();
      this.datiSezioneIniziale = new Array();
      this.datiSezioneFinale = new Array();
  }

  ngOnInit() {
      const id = this.route.snapshot.paramMap.get("id");
      this.api.getArginatura(id).subscribe(
          data => {
              this.argine = data['data'];
              this.argine.mappe.forEach(
                  mappa => {
                    this.mappePdf.push({
                        label: mappa.filename,
                        url: this.api.getPdfUrl() + '/' + mappa.filename
                    });
                  }
              );

              let datiX = this.argine.sezione_iniziale_x.split('\n');
              let datiY = this.argine.sezione_iniziale_y.split('\n');

              for (var i = 0; i < datiX.length; i++) {
                  this.datiSezioneIniziale.push([parseFloat(datiX[i]), parseFloat(datiY[i])]);
              }

              datiX = this.argine.sezione_finale_x.split('\n');
              datiY = this.argine.sezione_finale_y.split('\n');

              for (i = 0; i < datiX.length; i++) {
                  this.datiSezioneFinale.push([parseFloat(datiX[i]), parseFloat(datiY[i])]);
              }

              this.showGraphs = true;

          }
      );

      this.imgUrl = this.api.getImgUrl();
  }

  downloadMappa() {
      this.message.add(
          {
              severity: 'warn',
              summary: 'Attenzione',
              detail: 'Selezionare il file da scaricare dalla tendina'
          }
      );
  }

  downloadScheda() {

       if (!this.argine.scheda) {
           this.message.add(
               {
                   severity: 'warn',
                   summary: 'Attenzione',
                   detail: 'Il file non è stato caricato'
               }
           );
       } else {
           window.location.href = this.api.getSchedeUrl() + '/' + this.argine.scheda;
       }
  }

}
