import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-vulnerabilita',
  templateUrl: './vulnerabilita.component.html',
  styleUrls: ['./vulnerabilita.component.css']
})
export class VulnerabilitaComponent implements OnInit {
  public jsonVulnerabilita;
  public jsonRegioni;
  public jsonProvince;
  public jsonUsers;
  public progressBar = true;

  public users = [
    { label: 'Tutti', value: null},
  ];

  public regioni = [
    { label: 'Tutte', value: null},
  ];

  public province = [
    { label: 'Tutte', value: null},
  ];

  public validato = [
    { label: '-', value: null},
    { label: 'Si', value: 1},
    { label: 'No', value: 0},
  ];

  constructor(public api: ApiService, public confirm: ConfirmationService,
    public message: MessageService, public auth: AuthService, public router: Router, public messageService: MessageService) {
      if (!this.auth.isLoggedIn()) {
        this.router.navigate(['/login']);
      }
    }

  ngOnInit() {
    this.api.getRegioni().subscribe(
      data => {
        this.jsonRegioni = data;
        this.jsonRegioni.data.forEach(e => {
          this.regioni.push({label: e.nome,  value: e.nome});
        });
      }
    );

    this.api.getProvince().subscribe(
      data => {
        this.jsonProvince = data;
        this.jsonProvince.data.forEach(e => {
          this.province.push({label: e.nome,  value: e.nome});
        });
      }
    );

    this.api.getUtenti().subscribe(
      data => {
        this.jsonUsers = data;
        this.jsonUsers.data.forEach(e => {
          this.users.push({label: e.name,  value: e.name});
        });
      }
    );

    this.api.getVulnerabilitaArgini().subscribe(
       data => {
         this.jsonVulnerabilita = data['data'];
         this.hideProgressBar();
       }
    );

  }

  approveVulnerabilita(element) {

    let message;
    if (element.valida === 1) {
      message = 'Annullare la validazione?';
    } else {
      message = 'Procedere con la validazione?';
    }

    this.confirm.confirm({
      message: message,
      header: 'Richiesta conferma',
      acceptLabel: 'sì',
      rejectLabel: 'no',
      accept: () => {
        let value;
        if (element.valida === 1) {
           value = 0;
        } else {
          value = 1;
        }
        this.api.validateVulnerabilita({id: element.id, validate: value}).subscribe(
          data => {
            if (data['status'] === 'ok') {
              this.progressBar = true;
              this.message.add(
                {
                  severity: 'success',
                  summary: 'Conferma',
                  detail: 'La vulnerabilità è stata approvata con successo!'
                }
              );

              this.api.getVulnerabilitaArgini().subscribe(
                data => {
                  this.jsonVulnerabilita = data['data'];
                  this.hideProgressBar();
                }
              );
            } else {
              this.message.add(
                {
                  severity: 'error',
                  summary: 'Errore',
                  detail: data['reason']
                }
              );
            }
          }
        );
      }
    });
  }

  confirmDelete(element) {
    this.confirm.confirm({
      message: 'Procedere con la cancellazione?',
      header: 'Richiesta conferma',
      acceptLabel: 'sì',
      rejectLabel: 'no',
      accept: () => {
        this.api.deleteVulnerabilita(element.id).subscribe(
          data => {
            if (data['status'] === 'ok') {
              const index = this.jsonVulnerabilita.indexOf(element);
              this.jsonVulnerabilita = this.jsonVulnerabilita.filter((val, i) => i !== index );
            } else {
              this.messageService.add(
                {
                  severity: 'error',
                  summary: 'Errore',
                  detail: data['reason']
                }
              );
            }
          }
        );
      }
    });
  }

  hideProgressBar() {
    this.progressBar = false;
  }

}
