import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private apiURL = environment.apiURL;
  private imgURL = environment.imgURL;
  private vulnerabilitaImgURL = environment.vulnerabilitaImgURL;
  private pdfURL = environment.pdfURL;
  private schedeURL = environment.schedeURL;

  constructor(private http: HttpClient) {
  }

  public getApiUrl() {
    return this.apiURL;
  }

  public getImgUrl() {
    return this.imgURL;
  }

  public getVulnerabilitaImgUrl() {
    return this.vulnerabilitaImgURL;
  }

  public getPdfUrl() {
      return this.pdfURL;
  }

  public getSchedeUrl() {
      return this.schedeURL;
  }

  // Login api call

  public login(username: string, password: string){
      return this.http.post(this.apiURL + '/login', {email: username, password: password});
  }

  public logout() {
      return this.http.post(this.apiURL + '/logout', {});
  }

  // Arginature api calls

  public getArginature() {
    return this.http.get(this.apiURL + '/arginature/index');
  }

  public getArginatura(id) {
      return this.http.get(this.apiURL + '/arginature/view/' + id);
  }

  public storeArginatura() {
      const data = {}
      return this.http.post(this.apiURL + '/arginature/store', data);
  }

  public updateArginatura(data) {
    return this.http.post(this.apiURL + '/arginature/update', data);
  }

  public deleteArginatura(id) {
      return this.http.post(this.apiURL + '/arginature/delete', {id: id} );
  }

  public deleteScheda(id) {
      return this.http.post(this.apiURL + '/arginature/deletescheda', {id: id} );
  }

  // Regioni api calls

  public getRegioni() {
    return this.http.get(this.apiURL + '/regioni/index');
  }

  public getRegioniProvince() {
    return this.http.get(this.apiURL + '/regioni/province');
  }

  public getProvince() {
    return this.http.get(this.apiURL + '/province/index');
  }

  // Corsi d'acqua api calls

  public getCorsi() {
      return this.http.get(this.apiURL + '/corsi/index');
  }

  public getCorso(id) {
      return this.http.get(this.apiURL + '/corsi/view/' + id);
  }

  public storeCorso(data) {
      return this.http.post(this.apiURL + '/corsi/store', data);
  }

  public updateCorso(data) {
      return this.http.post(this.apiURL + '/corsi/update', data);
  }

  public deleteCorso(id) {
      return this.http.post(this.apiURL + '/corsi/delete', {id: id} );
  }

  // Vulnerabilità api calls

  public getVulnerabilitaArgini() {
      return this.http.get(this.apiURL + '/vulnerabilita/index');
  }

  public getVulnerabilitaArgine(id) {
    return this.http.get(this.apiURL + '/vulnerabilita/view/' + id);
  }

  public storeVulnerabilita(data) {
      return this.http.post(this.apiURL + '/vulnerabilita/store', data);
  }

  public updateVulnerabilita(data) {
    return this.http.post(this.apiURL + '/vulnerabilita/update', data);
  }

  public validateVulnerabilita(data) {
    return this.http.post(this.apiURL + '/vulnerabilita/validation', data);
  }

  public deleteVulnerabilita(id) {
    return this.http.post(this.apiURL + '/vulnerabilita/delete', {id: id} );
  }

  // Immagini api calls

  public deleteImmagine(id) {
        return this.http.post(this.apiURL + '/immagini/delete', {id: id} );
  }

  // Mappe PDF api calls

  public deleteMappa(id) {
      return this.http.post(this.apiURL + '/mappe/delete', {id: id} );
  }

  // Utenti

  public getUtenti() {
      return this.http.get(this.apiURL + '/utenti/index');
  }

  public getUtente(id) {
      return this.http.get(this.apiURL + '/utenti/view/' + id);
  }

  public storeUtente(data) {
        return this.http.post(this.apiURL + '/utenti/store', data);
  }

  public updateUtente(data) {
        return this.http.post(this.apiURL + '/utenti/update', data);
  }

  public deleteUtente(id) {
        return this.http.post(this.apiURL + '/utenti/delete', {id: id} );
  }

  public changePasswordUtente(id, password) {
     return this.http.post(this.apiURL + '/utenti/password', {id: id, password: password} );
  }

  // Reset password

  public resetPassword(email) {
     return this.http.post(this.apiURL + '/utenti/reset', {email: email} );
  }

  public newPassword(password, hash) {
        return this.http.post(this.apiURL + '/utenti/changeuserpassword', {password: password, hash: hash} );
  }

}
