export class Vulnerabilita {
    public id: number;
    public user: string;

    public regione_id: number;
    public regione: string;

    public provincia_id: number;
    public provincia: string;

    public corso_id: number; // se selezionato e presente
    public corso: string; // se nuovo

    public latitudine_i : number;
    public latitudine_f : number;
    public longitudine_i : number;
    public longitudine_f : number;
    public elle: number;
    // public kappa: string;
    public hs: number;
    public xdxmedia: number;
    public zmedia: number;
    public h0_200: number;
    public h0_arg: number;
    public D: number;
    public a_fond: number;
    public porosita: number;
    public valida: number;
}
