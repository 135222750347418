import { Component, OnInit } from '@angular/core';
import { Vulnerabilita } from '../_models/vulnerabilita.model';
import { AuthService } from '../auth.service';
import {ApiService} from '../api.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-vulnerabilita-result',
  templateUrl: './vulnerabilita-result.component.html',
  styleUrls: ['./vulnerabilita-result.component.css']
})
export class VulnerabilitaResultComponent implements OnInit {
  public vulnerabilita: Vulnerabilita;

  public id: string;

  constructor(
    public auth: AuthService,
    public api: ApiService,
    public router: Router,
    public route: ActivatedRoute,
  ) {
    this.vulnerabilita = new Vulnerabilita();
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.api.getVulnerabilitaArgine(this.id).subscribe(data => {
      this.vulnerabilita = data['data'];
    });
  }

  getImageUrl1() {
    return this.api.getVulnerabilitaImgUrl() + '/' + this.id + '_iv.png';
  }

  getImageUrl2() {
    return this.api.getVulnerabilitaImgUrl() + '/' + this.id + '_prob_non_superamento.png';
  }


}
