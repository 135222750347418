import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { AuthService } from '../auth.service';
import {ApiService} from '../api.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor(public router: Router, public auth: AuthService, public api: ApiService) { }

  ngOnInit() {
  }

  logout() {
      this.api.logout().subscribe(
          data => {
              if (data['status'] == 'ok') {
                 this.auth.logout();
                 this.router.navigate(['/login']);
              }
          }
      );
  }

}
