import { Component } from '@angular/core';
import { Location } from '@angular/common';
import {AuthService} from './auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public title = 'DANTE: Database nazionale delle ArgiNature in TErra';

  constructor(public location: Location, public auth: AuthService) { }

  isLoginRouteActivated(): boolean {
    return this.location.path().indexOf('/login') > -1;
  }

  isResetRouteActivated(): boolean {
    return this.location.path().indexOf('/reset') > -1;
  }

  isNewPswRouteActivated(): boolean {
    return this.location.path().indexOf('/new-password') > -1;
  }
}
