import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-utenti-new',
  templateUrl: './utenti-new.component.html',
  styleUrls: ['./utenti-new.component.css']
})
export class UtentiNewComponent implements OnInit {

 public ruolo = [
      { label: 'Tutti', value: null},
      { label: 'Admin', value: 'admin'},
      { label: 'Read-write', value: 'rw'},
      { label: 'Read only', value: 'ro'},
  ];

  constructor() { }

  ngOnInit() {
  }

}
