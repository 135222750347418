import {Component, OnInit, ViewChild} from '@angular/core';
import { Corso } from '../_models/corso.model';
import { NgForm } from '@angular/forms';
import {ApiService} from '../api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {AuthService} from '../auth.service';

@Component({
  selector: 'app-corsidacqua-edit',
  templateUrl: './corsidacqua-edit.component.html',
  styleUrls: ['./corsidacqua-edit.component.css']
})
export class CorsidacquaEditComponent implements OnInit {

  public corso: Corso;
  public isNew = true;

  @ViewChild('f') formValues;

  constructor(public api: ApiService, public route: ActivatedRoute, public message: MessageService,
              public auth: AuthService, public router: Router) {

      if (!this.auth.userCanWrite()) {
          this.router.navigate(['/login']);
      }
  }

  ngOnInit() {
    this.corso = new Corso();

    const id = this.route.snapshot.paramMap.get("id");
    if (id) {
      this.isNew = false;
    }

    if (!this.isNew) {

        this.api.getCorso(id).subscribe(
            data => {
                this.corso = data['data'];
            }
        );
    }

  }

  onSubmit(form: NgForm) {

      if (this.isNew) {
          this.api.storeCorso({nome: form.value.nome}).subscribe(
              data => {
                  if (data['status'] === 'ok') {

                      this.formValues.resetForm();

                      this.message.add(
                          {
                              severity: 'success',
                              summary: 'Conferma',
                              detail: 'Il corso d\'acqua è stato creato con successo!'
                          }
                      );
                      //this.router.navigate(['/corsidacqua']);
                  } else {

                      this.message.add(
                          {
                              severity: 'error',
                              summary: 'Errore',
                              detail: data['reason']
                          }
                      );

                  }
              }
          );
      } else {

        this.api.updateCorso(this.corso).subscribe(
            data => {

                if (data['status'] === 'ok') {

                    this.message.add(
                        {
                            severity: 'success',
                            summary: 'Conferma',
                            detail: 'Il corso d\'acqua è stato modificato con successo!'
                        }
                    );
                    //this.router.navigate(['/corsidacqua']);
                } else {

                    this.message.add(
                        {
                            severity: 'error',
                            summary: 'Errore',
                            detail: data['reason']
                        }
                    );

                }

            }
        );

      }
  }

}
