
import { Immagine } from './immagine.model';

export class Immagini {
    public tr50: Immagine[];
    public tr200: Immagine[];
    public tr500: Immagine[];

    constructor() {
        this.tr50 = new Array<Immagine>();
        this.tr200 = new Array<Immagine>();
        this.tr500 = new Array<Immagine>();
    }
}