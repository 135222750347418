import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { RouterModule, Routes} from '@angular/router';

import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatSortModule, MatTableModule, MatIconModule, MatButtonModule, MatPaginatorModule } from '@angular/material';
import { ArginatureComponent } from './arginature/arginature.component';
import { VulnerabilitaComponent } from './vulnerabilita/vulnerabilita.component';
import { CorsidacquaComponent } from './corsidacqua/corsidacqua.component';

import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import {ConfirmationService, ConfirmDialogModule, DropdownModule, MessageService, SplitButtonModule} from 'primeng/primeng';
import { ButtonModule} from 'primeng/button';
import { FileUploadModule } from 'primeng/fileupload';
import { EditorModule } from 'primeng/editor';
import { CardModule } from 'primeng/card';
import { TooltipModule } from 'primeng/tooltip';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';
import { ArginatureViewComponent } from './arginature-view/arginature-view.component';
import { ArginatureEditComponent } from './arginature-edit/arginature-edit.component';
import { VulnerabilitaEditComponent } from './vulnerabilita-edit/vulnerabilita-edit.component';
import { VulnerabilitaResultComponent } from './vulnerabilita-result/vulnerabilita-result.component';

import { CorsidacquaEditComponent } from './corsidacqua-edit/corsidacqua-edit.component';

import { UtentiComponent } from './utenti/utenti.component';
import { UtentiEditComponent } from './utenti-edit/utenti-edit.component';
import { UtentiNewComponent } from './utenti-new/utenti-new.component';

import { HTTP_INTERCEPTORS} from '@angular/common/http';
import { AuthInterceptorService } from './auth-interceptor.service';
import { ErrorInterceptorService } from './error-interceptor.service';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NewPasswordComponent } from './new-password/new-password.component';

import { ToastModule } from 'primeng/toast';
import {ProgressBarModule} from 'primeng/progressbar';

import { GoogleChartsModule } from 'angular-google-charts';
import { UtentiPasswordComponent } from './utenti-password/utenti-password.component';
import { HomepageComponent } from './homepage/homepage.component';

const appRoutes: Routes = [
    { path: 'login/:param',  component: LoginComponent },
    { path: 'reset',  component: ResetPasswordComponent },
    { path: 'new-password/:hash',  component: NewPasswordComponent },
    { path: 'utenti',  component: UtentiComponent },
    { path: 'utenti/new',  component: UtentiEditComponent },
    { path: 'utenti/edit/:id',  component: UtentiEditComponent },
    { path: 'utenti/password/:id',  component: UtentiPasswordComponent },
    { path: 'arginature',  component: ArginatureComponent },
    { path: 'arginature/view/:id',  component: ArginatureViewComponent },
    { path: 'arginature/new',  component: ArginatureEditComponent },
    { path: 'arginature/edit/:id',  component: ArginatureEditComponent },
    { path: 'vulnerabilita',  component: VulnerabilitaComponent },
    { path: 'vulnerabilita/new',  component: VulnerabilitaEditComponent },
    { path: 'vulnerabilita/edit/:id',  component: VulnerabilitaEditComponent },
    { path: 'vulnerabilita/result/:id',  component: VulnerabilitaResultComponent },
    { path: 'corsidacqua',  component: CorsidacquaComponent },
    { path: 'corsidacqua/new',  component: CorsidacquaEditComponent },
    { path: 'corsidacqua/edit/:id',  component: CorsidacquaEditComponent },
    { path: 'home',  component: HomepageComponent },
    { path: '', component: LoginComponent },
    { path: '**', component: LoginComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    CorsidacquaComponent,
    CorsidacquaEditComponent,
    LoginComponent,
    ArginatureComponent,
    ArginatureViewComponent,
    ArginatureEditComponent,
    VulnerabilitaComponent,
    VulnerabilitaEditComponent,
    VulnerabilitaResultComponent,
    UtentiComponent,
    UtentiEditComponent,
    UtentiNewComponent,
    ResetPasswordComponent,
    NewPasswordComponent,
    UtentiPasswordComponent,
    HomepageComponent,
  ],
  imports: [
    BrowserModule,
    MatTableModule,
    MatSortModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatButtonModule,
    HttpClientModule,
    MatPaginatorModule,
    TableModule,
    InputTextModule,
    DropdownModule,
    ButtonModule,
    FileUploadModule,
    EditorModule,
    CardModule,
    TooltipModule,
    FormsModule,
    RouterModule.forRoot(appRoutes),
    ConfirmDialogModule,
    SplitButtonModule,
    ToastModule,
    ProgressBarModule,
    GoogleChartsModule.forRoot()
  ],
  providers: [
      { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
      ConfirmationService,
      MessageService
      ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
