import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from '../api.service';
import { AuthService } from '../auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.css']
})
export class NewPasswordComponent implements OnInit {
  public hash: string;
  public password1: string;
  public password2: string;

  constructor(
    public api: ApiService,
    public auth: AuthService,
    public router: Router,
    public route: ActivatedRoute,
    public message: MessageService
    ) {
      if (auth.isLoggedIn()) {
        this.router.navigate(['/login']);
      }
  
      this.hash = this.route.snapshot.paramMap.get('hash');
    }

  ngOnInit() { }

  onSubmit(form: NgForm) {
     if (this.password1 !== this.password2) {
        this.message.add(
          {
            severity: 'error',
            summary: 'Errore',
            detail: 'Le password non coincidono'
          }
        );
        form.reset();
    } else if (this.password1.length < 8) {
      this.message.add(
        {
          severity: 'error',
          summary: 'Errore',
          detail: 'Password inferiore ad 8 caratteri'
        }
      );
     } else {
      this.api.newPassword(this.password1, this.hash).subscribe(
        data => {
          if (data['status'] === 'ok') {
            this.message.add(
              {
                severity: 'success',
                summary: 'Password modificata',
                detail: data['message']
              }
            );
            form.reset();
          } else {
            this.message.add(
              {
                severity: 'error',
                summary: 'Errore',
                detail: data['reason']
              }
            );
          }
        }
      );
    }
  }
}
