
import { Immagini } from './immagini.model';
import { Mappa } from './mappa.model';

export class Arginatura {
    public id: number;
    public codice: string;
    public hash: string;

    public regione_id: number;
    public regione: string;

    public provincia_id: number;
    public provincia: string;

    public corso_id: number;
    public corso: string;
    public corso_d_acqua: string;

    public p_idraulica: string;

    public sormonto_tr50: string;
    public sormonto_tr200: string;
    public sormonto_tr500: string;

    public sifonamento_tr50_12: string;
    public sifonamento_tr50_24: string;
    public sifonamento_tr50_48: string;

    public sifonamento_tr200_12: string;
    public sifonamento_tr200_24: string;
    public sifonamento_tr200_48: string;

    public sifonamento_tr500_12: string;
    public sifonamento_tr500_24: string;
    public sifonamento_tr500_48: string;

    public pericolosita_idraulica: string;
    public controllo_manutenzione: string;

    public sezione_iniziale_x: string;
    public sezione_iniziale_y: string;
    public sezione_finale_x: string;
    public sezione_finale_y: string;

    public latitudine_i : number;
    public latitudine_f : number;
    public longitudine_i : number;
    public longitudine_f : number;

    public tr500_12: string;

    public immagini: Immagini;

    public mappe: Mappa[];

    public scheda: string;

    constructor() {
        this.immagini = new Immagini();
        this.mappe = new Array<Mappa>();
    }

}