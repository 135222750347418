import { Injectable } from '@angular/core';
import {HttpClient, HttpParams, HttpRequest, HttpEvent} from '@angular/common/http';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UploadService {

    constructor(private http: HttpClient) { }

    // file from event.target.files[0]

    uploadImg(url: string, file: File, id: string, tr: string): Observable<HttpEvent<any>> {

        let formData = new FormData();
        formData.append('upload', file);
        formData.append('id', id);
        formData.append('tr', tr);

        let params = new HttpParams();

        const options = {
            params: params,
            reportProgress: true,
        };

        const req = new HttpRequest('POST', url, formData, options);
        return this.http.request(req);
    }

    uploadPdf(url: string, file: File, id: string): Observable<HttpEvent<any>> {

        let formData = new FormData();
        formData.append('upload', file);
        formData.append('id', id);

        let params = new HttpParams();

        const options = {
            params: params,
            reportProgress: true,
        };

        const req = new HttpRequest('POST', url, formData, options);
        return this.http.request(req);
    }

    uploadScheda(url: string, file: File, id: string): Observable<HttpEvent<any>> {

        let formData = new FormData();
        formData.append('upload', file);
        formData.append('id', id);

        let params = new HttpParams();

        const options = {
            params: params,
            reportProgress: true,
        };

        const req = new HttpRequest('POST', url, formData, options);
        return this.http.request(req);
    }
}