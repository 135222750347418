import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService, MessageService} from 'primeng/api';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-test',
  templateUrl: './arginature.component.html',
  styleUrls: ['./arginature.component.css']
})

export class ArginatureComponent implements OnInit {

  public jsonArginature;
  public jsonRegioni;
  public jsonProvince;
  public progressBar = true;

  public regioni = [
      { label: 'Tutte', value: null},
  ];

  public province = [
      { label: 'Tutte', value: null},
  ];

  public sormonti = [
      { label: 'Tutte', value:  null },
      { label: 'Nulla', value:  'nulla' },
      { label: 'Bassa', value: 'bassa' },
      { label: 'Media', value: 'media' },
      { label: 'Alta', value: 'alta' }
  ];

  public sifonamenti  = [
        { label: 'Tutte', value:  null },
        { label: 'Nulla', value:  'nulla' },
        { label: 'Bassa', value: 'bassa' },
        { label: 'Media', value: 'media' },
        { label: 'Alta', value: 'alta' },
        { label: 'Tracimato', value: 'tracimato' }
  ];

  constructor(public api: ApiService, public confirm: ConfirmationService,
              public message: MessageService, public auth: AuthService, public router: Router) {

      if (!this.auth.isLoggedIn()) {
          this.router.navigate(['/login']);
      }
  }

  ngOnInit() {

      this.api.getRegioni().subscribe(
          data => {
              this.jsonRegioni = data;
              this.jsonRegioni.data.forEach(e => {
                  this.regioni.push({label: e.nome,  value: e.nome});
              });
          }
      );

      this.api.getProvince().subscribe(
          data => {
              this.jsonProvince = data;
              this.jsonProvince.data.forEach(e => {
                  this.province.push({label: e.nome,  value: e.nome});
              });
          }
      );

      this.api.getArginature().subscribe(
          data => {
              this.jsonArginature = data['data'];
              this.hideProgressBar();
          }
      );
  }

  confirmDelete(element) {
        this.confirm.confirm({
            message: 'Procedere con la cancellazione?',
            header: 'Richiesta conferma',
            acceptLabel: 'sì',
            rejectLabel: 'no',
            accept: () => {
                this.api.deleteArginatura(element.id).subscribe(
                    data => {
                        if (data['status'] === 'ok') {
                            const index = this.jsonArginature.indexOf(element);
                            this.jsonArginature = this.jsonArginature.filter((val, i) => i !== index );
                        } else {
                            this.message.add(
                                {severity: 'error',
                                    summary: 'Errore',
                                    detail: data['reason']}
                            );
                        }
                    }
                );
            }
        });
  }

  hideProgressBar() {
      this.progressBar = false;
  }

}
