import { Component, OnInit } from '@angular/core';
import { Vulnerabilita } from '../_models/vulnerabilita.model';
import { ApiService } from '../api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AuthService } from '../auth.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-vulnerabilita-edit',
  templateUrl: './vulnerabilita-edit.component.html',
  styleUrls: ['./vulnerabilita-edit.component.css']
})
export class VulnerabilitaEditComponent implements OnInit {
  public vulnerabilita: Vulnerabilita;
  public isNew = true;
  public saveBtnDisabled = false;
  public approveBtnDisabled = false;

  public regioni = [
    { label: 'Tutte', value: null, province: []},
  ];

  public province = [];

  public corsidacqua = [];

  constructor(
    public api: ApiService,
    public route: ActivatedRoute,
    public message: MessageService,
    public confirm: ConfirmationService,
    public auth: AuthService,
    public router: Router,
    public messageService: MessageService) {
    if (!this.auth.userCanReadWrite()) {
      this.router.navigate(['/login']);
    }

    this.vulnerabilita = new Vulnerabilita();
  }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get("id");
    if (id) {
      this.isNew = false;
      this.vulnerabilita.id = parseInt(id, 10);
    }

    this.api.getRegioniProvince().subscribe(
      dataP => {
        this.regioni = dataP['data'];

        this.api.getCorsi().subscribe(
          dataC => {
            const d = dataC;
            d['data'].forEach(c => {
              this.corsidacqua.push({label: c.nome, value: c.id});
            });

            if (!this.isNew) {
              this.api.getVulnerabilitaArgine(this.vulnerabilita.id).subscribe(
                data => {
                  this.vulnerabilita = data['data'];
                  this.regioni.forEach(r => {
                    if (r.value === this.vulnerabilita.regione_id) {
                      this.province = r.province;
                    }
                  });

                }
              );
            }

          }
        );

      }
    );
  }

  approveVulnerabilita(form: NgForm) {
    this.approveBtnDisabled = true;
    this.confirm.confirm({
      message: 'Procedere con l\'approvazione?',
      header: 'Richiesta conferma',
      acceptLabel: 'sì',
      rejectLabel: 'no',
      accept: () => {
        this.vulnerabilita.valida = 1;
        this.api.validateVulnerabilita({id: this.vulnerabilita.id, validate: 1}).subscribe(
          data => {
            if (data['status'] === 'ok') {
              this.message.add(
                {
                  severity: 'success',
                  summary: 'Conferma',
                  detail: 'La vulnerabilità è stata approvata con successo!'
                }
              );
              const _self = this;
              setTimeout(function() {
                _self.router.navigate(['/vulnerabilita']);
              }, 1500);
              for (const property in form.controls) {
                form.controls[property].disable();
              }
            } else {
              this.message.add(
                {
                  severity: 'error',
                  summary: 'Errore',
                  detail: data['reason']
                }
              );
            }
          }
        );
      }
    });
  }

  selectCorsoAcqua(e) {
    if(e.originalEvent.type === 'click') {
      this.vulnerabilita.corso_id = e.value;
      this.vulnerabilita.corso = null;
    } else {
      this.vulnerabilita.corso_id = null;
      this.vulnerabilita.corso = e.value;
    }
  }

  onChangeRegione($event) {
    this.regioni.forEach(r => {
      if (r. value === $event.value) {
        this.province = r.province;
      }
    });
  }

  onSubmit(form: NgForm) {
    this.saveBtnDisabled = true;
    if (this.isNew) {
      this.api.storeVulnerabilita(this.vulnerabilita).subscribe(
        data => {
          this.saveBtnDisabled = false;
          if (data['status'] === 'ok') {
            form.resetForm();

            this.message.add(
              {
                severity: 'success',
                summary: 'Conferma',
                detail: 'Vulnerabilità creata con successo!'
              }
            );
          } else {
            this.message.add(
              {
                severity: 'error',
                summary: 'Errore',
                detail: data['reason']
              }
            );
          }
        }
      );
    } else {
      this.api.updateVulnerabilita(this.vulnerabilita).subscribe(
        data => {
          this.saveBtnDisabled = false;
          if (data['status'] === 'ok') {
            this.message.add(
              {
                severity: 'success',
                summary: 'Conferma',
                detail: 'La vulnerabilità è stata modificata con successo!'
              }
            );
          } else {
            this.message.add(
              {
                severity: 'error',
                summary: 'Errore',
                detail: data['reason']
              }
            );
          }
        }
      );
    }
  }

}
