import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private helper = new JwtHelperService();

    constructor() {

    }

    isLoggedIn() {

        var token;

        if (!localStorage.getItem('jwt_access_token')) {
            return false;
        } else {
            token = localStorage.getItem('jwt_access_token');
        }

        if (this.helper.isTokenExpired(token)) {
            return false;
        }

        return true;
    }

    getToken() {
        if (localStorage.getItem('jwt_access_token')) {
            return localStorage.getItem('jwt_access_token');
        }
    }

    logout() {
        localStorage.removeItem('jwt_access_token');
        localStorage.removeItem('jwt_expires_in');
        localStorage.removeItem('jwt_token_type');
    }

    userIsAdmin() {
        if (this.isLoggedIn()) {
            const token = this.helper.decodeToken(localStorage.getItem('jwt_access_token'));
            if (token['role'] === 'admin') {
                return true;
            }
        }
        return false;
    }

    userCanWrite() {
        if (this.isLoggedIn()) {
            const token = this.helper.decodeToken(localStorage.getItem('jwt_access_token'));
            if (token['role'] === 'admin' || token['role'] === 'rw') {
                return true;
            }
        }
        return false;
    }

    // used only on vulnerabilità
    userCanReadWrite() {
        if (this.isLoggedIn()) {
            const token = this.helper.decodeToken(localStorage.getItem('jwt_access_token'));
            if (token['role'] === 'admin' || token['role'] === 'ro' || token['role'] === 'rw') {
                return true;
            }
        }
        return false;
    }
}
